<template>
  <div :class="$store.state.tyPhone == 'ios'?'chooseList iosList':'chooseList'">
      <nkdetails v-if="types == 1"></nkdetails>
      <nkopen v-else></nkopen>
      <goPage v-if="$store.state.tyPhone == 'ios'"></goPage>
  </div>
</template>

<script>
import { getContentByColumnId,getServiceContentByColumnId} from '@/api/home.js'
import nkdetails from '@/views/details/nkdetails/nkdetails.vue'
import nkopen from '@/views/details/nkopen/nkopen.vue'
export default {
    name:"chooseList",
    components:{
        nkopen,
        nkdetails
    },
    computed:{
        menulist(){
            return this.$store.state.menulist
        },
        menuType(){
            return this.$store.state.menuType
        }
    },
    activated: function () {
      this.$setgoindex()
    },
    watch: {},
    data(){
        return{
            types:1,
            columnType:'',
            columnAttr:'',
            columnStyle:'',
            categoryId:''
        }
    },
    created(){
        this.init()
    },
    methods:{
        async init(){
            if(this.$route.query.columnType || this.$route.query.categoryId){
                this.columnType = this.$route.query.columnType
                this.categoryId = this.$route.query.categoryId
            }else{
                this.columnType = this.menulist.columnType
                this.categoryId = this.categoryId
            }
            if(this.columnType == 1){
                // 单极
                if(this.$route.query.columnAttr){
                    this.columnAttr = this.$route.query.columnAttr
                }else{
                    this.columnAttr = this.menulist.columnAttr
                }
                if(this.columnAttr == 1){
                    // 列表
                    this.$store.commit('setStyleType',5)
                    this.$store.commit('setDetailsId',this.categoryId)
                    this.$store.commit('setListId',this.menulist.listType)
                    this.$store.commit('setColumnStyle',6)
                }else{
                    this.$store.commit('setStyleType',1)
                    if(this.menuType == 'lanmu'){
                        let data = await getContentByColumnId({
                            "columnId":this.categoryId,
                            "pageNumber": 1,
                            "pageSize": 90071992
                        })
                        this.$store.commit('setDetailsId',data.data.data.rows[0].id)
                    }else{
                        let data = await getServiceContentByColumnId({
                            "columnId":this.categoryId,
                            "pageNumber": 1,
                            "pageSize": 90071992
                        })
                        this.$store.commit('setDetailsId',data.data.data.rows[0].id)
                    }
                }
                this.types = 2
            }else{
                // 多级
                
                this.$store.commit('setDetailsId',this.categoryId)
                if(this.$route.query.columnStyle){
                    this.columnStyle = this.$route.query.columnStyle
                }else{
                    this.columnStyle = this.menulist.columnStyle
                }
                if(this.columnStyle == 1){
                    this.$store.commit('setColumnStyle',1)
                    // tab
                }else if(this.columnStyle == 2){
                    // 列表卡片
                    this.$store.commit('setColumnStyle',2)
                }else{
                    // 平铺按钮
                    // localStorage.setItem('btnTittle',this.menulist.columnName)
                    this.$store.commit('setTopTittle',this.menulist.columnName)
                    this.$store.commit('setColumnStyle',3)
                }
                this.types = 1
            }
        }
    }
}
</script>

<style lang="less" scoped>
.chooseList{}
.iosList{
    padding-bottom: 1.778rem;
}
</style>